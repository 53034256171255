<template lang="html">
  <div class="">
    <div class="personalCenter mainBox">
      <div class="alreadyBought">
        <div class="title">
          已购产品
        </div>
        <div class="box">
          <div class="list" @click="$router.push({path: `/detail?id=${item.productId}`})" v-for="(item, index) in listData" :key="index">
            <div class="left">
              <img :src="item.productLogo" v-if="item.productLogo" />
              <img v-else src="../assets/img/chanpin.png" alt="">
            </div>
            <div class="right">
              <div class="name">
                <div class="nameText">
                  {{item.productName}}
                </div>
                <div class="address">
                  登录地址
                  <span class="copy" @click.stop="">复制</span>
                </div>
              </div>
              <div class="desc monery">
                ￥{{item.productPrice}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="recommend">
        <div class="title">
          为您推荐
        </div>
        <div class="box">
          <div class="list" @click="$router.push({path: `/detail?id=${item.productId}`})" v-for="(item, index) in productRecommendList" :key="index">
            <img :src="item.productLogo" alt="">
            <div class="content">
              <div class="left">
                <div class="name">
                  {{item.productName}}
                </div>
                <div class="monery">
                  ￥{{item.productPrice}}
                </div>
              </div>
              <div class="right">
                查看详情
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <myBase></myBase>
  </div>
</template>

<script>
  import myBase from '@/components/myBase.vue';

  export default {
    name: 'personalCenter',
    components: {
      myBase
    },
    data() {
      return {
        productRecommendList: [],
        listData: []
      };
    },
    created() {
      //do something after creating vue instance
      this.getUserInfo();
      this.getList();
      this.getProductRecommend();
    },
    methods: {
      getProductRecommend() {
        this.$request.HttpGet('/api/productRecommend', { page: 1, pageSize: 3 }).then(res => {
          this.productRecommendList = res.data.list || [];
        });
      },
      getUserInfo () {
        this.$request.HttpGet('/api/userInfo').then(res => {
          let userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
          userInfo.user = res.data;
          window.localStorage.setItem('userInfo', JSON.stringify(userInfo));
        });
      },
      getList() {
        this.$request.HttpGet('/api/productList', { page: 1, pageSize: 10000 }).then(res => {
          this.listData = res.data.list || [];
        });
      }
    }
  };
</script>

<style lang="scss" scoped>
  .personalCenter {
    min-height: calc(100vh - 215px);
    max-width: 1400px;
    min-width: 1200px;
    .alreadyBought {
      width: 100%;
      min-height: 156px;
      background: #FFFFFF;
      border: 1px solid #EEEEEE;
      margin-top: 20px;
      .title {
        height: 42px;
        line-height: 42px;
        border-bottom: 1px solid #C8C8C8;
        padding-left: 19px;
        font-size: 12px;
        color: #666666;
      }
      .box {
        display: flex;
        align-items: center;
        height: 113px;
        flex-wrap: wrap;
        padding: 0 20px;
        box-sizing: border-box;
        .list {
          width: 440px;
          height: 68px;
          margin-right: 20px;
          display: flex;
          &:nth-child(3n) {
            margin-right: 0;
          }
          .left {
            margin-right: 10px;
            img {
              width: 68px;
              height: 68px;
            }
          }
          .right {
            flex: 1;
            .name {
              margin-top: 18px;
              display: flex;
              align-items: flex-end;
              justify-content: space-between;
              .nameText {
                color: #010101;
              }
              .address {
                font-size: 12px;
                color: #999999;
                margin-right: 100px;
                span {
                  color: #3D7EFF;
                  cursor: pointer;
                }
              }
            }
            .desc {
                font-size: 16px;
                font-family: MicrosoftYaHei-Bold;
                font-weight: bold;
                color: #CB191A;
                margin-top: 5px;
            }
          }
        }
      }
    }
    .recommend {
      width: 100%;
      min-height: 305px;
      background: #FFFFFF;
      border: 1px solid #EEEEEE;
      margin-top: 20px;
      .title {
        height: 42px;
        line-height: 42px;
        border-bottom: 1px solid #C8C8C8;
        padding-left: 19px;
        font-size: 12px;
        color: #666666;
      }
      .box {
        //height: 262px;
        padding:  20px;
        box-sizing: border-box;
        align-items: center;
        flex-wrap: wrap;
        display: grid;
        justify-content: space-between;
        grid-template-columns: repeat(auto-fill, 440px);
        grid-gap: 10px;
        .list {
          flex-shrink: 0;
          max-width: 440px;
          height: 222px;
          margin-right: 20px;
          &:hover {
            box-shadow: 2px 7px 21px 0px rgba(0, 0, 0, 0.04);
            border-radius: 5px;
            background: #FFFFFF;
          }
          // &:nth-child(3n) {
          //   margin-right: 0;
          // }
          img {
            width: 100%;
            height: 150px;
          }
          .content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 72px;
            .left {
              margin-left: 40px;
              .name {
                color: #333333;
              }
              .monery {
                font-size: 16px;
                font-family: MicrosoftYaHei-Bold;
                font-weight: bold;
                color: #CB191A;
                margin-top: 5px;
              }
            }
            .right {
              width: 100px;
              height: 42px;
              background: #FFFFFF;
              border: 1px solid #C8C8C8;
              font-size: 12px;
              color: #666666;
              text-align: center;
              line-height: 42px;
              cursor: pointer;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
</style>
